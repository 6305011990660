import React from 'react';
import { BRAND } from '../../constants/main';
import { RangeStyledAC } from './AC/RangeStyled.AC';
import { RangeStyledAP } from './AP/RangeStyled.AP';
import { RangeStyledDS } from './DS/RangeStyled.DS';
import { RangeStyledOV } from './OV/RangeStyled.OV';
import { RangeStyled } from './RangeStyled';
import { SC } from '../../styles/theme';
import { RangeProps } from 'rc-slider';

type RangePropsWithSC = SC & Omit<RangeProps, 'activeDotStyle'>;

export interface RangeSliderProps extends RangePropsWithSC {
    availableMin?: number;
    availableMax?: number;
}

const Range = (props: RangeSliderProps) => {
    if (BRAND === 'OV') return <RangeStyledOV {...props} />;
    if (BRAND === 'AC') return <RangeStyledAC {...props} />;
    if (BRAND === 'AP') return <RangeStyledAP {...props} />;
    if (BRAND === 'DS') return <RangeStyledDS {...props} />;
    return <RangeStyled {...props} />;
};

export default Range;
