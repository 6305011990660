import styled from 'styled-components';
import { RangeTemplate } from './RangeTemplate';
import { rem } from 'polished';

export const RangeStyled = styled(RangeTemplate)`
    flex-basis: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: ${rem('40px')};
    position: relative;

    .rc-slider-handle-dragging {
        box-shadow: 0 0 2px 2px ${props => props.theme.colors.primaryHover};
        background-color: ${props => props.theme.colors.white};
    }
    .rc-slider-handle:hover {
        box-shadow: 0 0 1px 1px ${props => props.theme.colors.primaryHover};
    }
    .range-display {
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin: 0 ${rem('-7px')};

        > span:first-child > span:first-child {
            position: absolute;
            left: -5px;
        }
    }
`;
