import { FilterChipProps } from '.';
import React, { FC, useEffect, useState, useCallback } from 'react';
import {useMoneyFormatter} from "@hooks/useMoneyFormatter";

export const FilterChipTemplate: FC<FilterChipProps> = ({
    className,
    index,
    onClick,
    customClassName,
    countOfSelectedFilters,
    label,
    minPrice,
    maxPrice,
    dataId,
}) => {
    const { formatMoney } = useMoneyFormatter();

    return (
        <button
            key={index}
            className={`${className} ${customClassName} ${countOfSelectedFilters > 0 ? 'selected' : ''}`}
            onClick={onClick}
            data-testid={dataId}
        >
            {label && <span>{label}</span>}
            {minPrice && maxPrice && <span>{formatMoney(minPrice, true)} - {formatMoney(maxPrice, true)}</span>}
            {countOfSelectedFilters > 0 && <span className="count">{countOfSelectedFilters}</span>}
        </button>
    );
};
