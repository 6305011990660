import { css } from '../../../styles/theme';
import { rem } from 'polished';
import { ColorFilterItemProps } from './ColorFilterItem';

type ColorFilterItemStyledProps = Omit<ColorFilterItemProps, 'handleClick'>;

export const StyledColorWrapperDS = css<ColorFilterItemStyledProps>`
    border-radius: 0;
    &::after {
        border-radius: 0;
        border-color: ${({ theme }) => theme.colors.red1};
    }
    svg {
        position: relative;
        top: ${rem('-3px')};
        path {
            fill: ${({ theme }) => theme.colors.white};
        }
    }
`;
