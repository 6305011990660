import styled from 'styled-components';
import { rem } from 'polished';
import { isColorBright } from '@utils/Color.utils';
import { ColorFilterItemProps } from './ColorFilterItem';
import { isBrandDS } from '../../../constants/main';
import { StyledColorWrapperDS } from './ColorFilterItemStyled.DS';

type ColorFilterItemStyledProps = Omit<ColorFilterItemProps, 'handleClick'>;

export const StyledColorWrapper = styled.div<ColorFilterItemStyledProps>`
    width: ${rem('35px')};
    height: ${rem('35px')};
    background-color: ${({ color }) => color};
    position: relative;
    display: flex;
    justify-content: center;
    border-radius: 50%;
    align-items: center;
    margin-left: ${rem('10px')};
    border: solid 1px ${({ theme }) => theme.colors.grey4};
    margin-right: ${rem(5)};

    &:first-of-type {
        margin-left: 0;
    }

    &:hover {
        cursor: pointer;
    }

    &::after {
        content: '';
        position: absolute;
        top: ${rem('-5px')};
        left: ${rem('-5px')};
        width: ${rem('43px')};
        height: ${rem('43px')};
        border: solid 2px ${({ color, theme }) => theme.colors.primary};
        border-radius: 50%;
        ${({ isActive }) => (!isActive ? 'display: none;' : '')}
    }

    ${isBrandDS && StyledColorWrapperDS};
`;
