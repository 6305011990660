import React, { FC } from 'react';
import { StyledColorWrapper } from './ColorFilterItemStyled';
import Icon, { Icons } from '../../Icon';
import { isBrandDS } from '../../../constants/main';
import { AccessibleButton } from '@components/Button/AccessibleButton';
export interface ColorFilterItemProps {
    color: string;
    isActive: boolean;
    handleClick: () => void;
    className?: string;
    ariaLabel?: string;
}

const ColorFilterItem: FC<ColorFilterItemProps> = ({ color, isActive, handleClick, className, ariaLabel }) => (
    <AccessibleButton className="rounded" tabIndex={0} onClick={handleClick} aria-label={ariaLabel}>
        <StyledColorWrapper isActive={isActive} color={color} className={className}>
            {isBrandDS && isActive && (
                <Icon
                    className={`info-check ${color.includes('fff') ? 'isWhite' : ''}`}
                    name={Icons.Check}
                    width={14}
                    height={10}
                />
            )}
        </StyledColorWrapper>
    </AccessibleButton>
);

export default ColorFilterItem;
