import styled from 'styled-components';
import { FilterBoxStyled } from '../../FilterBoxStyled';
import { rem } from 'polished';

export const FilterBoxStyledOV = styled(FilterBoxStyled)`
    .header {
        .title {
            font-size: ${({ theme }) => theme.fontSizes.h2};
        }

        .closeButton {
            font-size: ${({ theme }) => theme.fontSizes.h3};
        }
    }

    .rc-slider {
        .rc-slider-track {
            background-color: ${({ theme }) => theme.colors.primary};
        }
    }

    .energy,
    .gear,
    .priceRange {
        font-size: ${({ theme }) => theme.fontSizes.h5};
        padding-top: 0;

        > span {
            font-size: ${rem(20)};
            padding-bottom: ${rem(15)};
        }

        .checked label {
            font-weight: ${({ theme }) => theme.fontWeights.textBold};
        }
    }
`;
