import { SC } from '../../styles/theme';
import { IFilterRule } from '../../redux/filters/filter.duck.interface';
import { BRAND, FILTER_CATEGORY, isMarketGB } from '../../constants/main';
import { FilterBoxStyledOV } from './OV/FilterBoxStyled.OV';
import { FilterBoxStyledOV as FilterBoxStyledOVGB } from './OV/GB/FilterBoxStyled.OV';
import { FilterBoxStyledAC } from './AC/FilterBoxStyled.AC';
import { FilterBoxStyledDS } from './DS/FilterBoxStyled.DS';
import { FilterBoxStyledAP } from './AP/FilterBoxStyled.AP';
import { FilterBoxStyled } from './FilterBoxStyled';
import React from 'react';

export interface FilterBoxProps extends SC {
    onChange?: (name: string, filter: IFilterRule[]) => void;
    onReset?: () => void;
    isOpen: boolean;
    closeFilter: any;
    onClickCheckbox?: ({ type, value }: { type: string; value: boolean }) => void;
    onClickCTA?: (label?: string) => void;
    selectedCategoryName?: FILTER_CATEGORY;
}

const FilterBox = (props: FilterBoxProps) => {
    if (BRAND === 'AC') return <FilterBoxStyledAC {...props} />;
    if (BRAND === 'OV') return isMarketGB ? <FilterBoxStyledOVGB {...props} /> : <FilterBoxStyledOV {...props} />;
    if (BRAND === 'AP') return <FilterBoxStyledAP {...props} />;
    if (BRAND === 'DS') return <FilterBoxStyledDS {...props} />;
    return <FilterBoxStyled {...props} />;
};

export default FilterBox;
