import styled from 'styled-components';
import { breakpoints } from '../../../styles/theme';
import { RangeStyled } from '../RangeStyled';

import { rem } from 'polished';

export const RangeStyledAP = styled(RangeStyled)`
    margin-top: 0;

    @media (min-width: ${breakpoints.md}px) {
        margin-top: ${rem('40px')};
    }

    /* design reset afte border-box sizing */
    .rc-slider {
        position: relative;
        height: 1.5rem;
        width: 90%;
        padding: 5px 0;
        left: 5px;

        @media (min-width: ${breakpoints.md}) {
            left: 0;
        }
    }

    .rc-slider-handle {
        cursor: grab;
        position: absolute;
        width: ${rem('20px')};
        height: ${rem('20px')};
        margin-top: ${rem('-6px')};
        border-radius: 50%;
        border: solid 2px #fff;
        background-color: ${props => props.theme.colors.white};
        border-color: ${props => props.theme.colors.primary};
    }
    .rc-slider-rail {
        position: absolute;
        width: 100%;
        background-color: ${props => props.theme.colors.primaryDisabled};
        height: 0.5rem;
        border-radius: 0.25rem;
    }
    .rc-slider-track {
        position: absolute;
        left: 0;
        height: 0.5rem;
        border-radius: 0.25rem;
        background-color: ${props => props.theme.colors.primary};
    }
`;
