import { SC } from '../../styles/theme';
import { MultiSelectStyled } from './MultiSelectStyled';
import React from 'react';
import { IFilterRule } from '../../redux/filters/filter.duck.interface';

export interface MultiSelectProps extends SC {
    options: IFilterRule[];
    handleChange: (value: any) => void;
}

const MultiSelect = (props: MultiSelectProps) => {
    return <MultiSelectStyled {...props} />;
};

export default MultiSelect;
