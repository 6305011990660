import styled from 'styled-components';
import { rem } from 'polished';
import { MultiSelectTemplate } from './MultiSelectTemplate';
import { isBrandAC, isBrandOV } from '../../constants/main';
import { StylesOV } from './Styles.OV';
import { StylesAC } from './Styles.AC';

export const MultiSelectStyled = styled(MultiSelectTemplate)`
    position: relative;
    display: flex;
    flex-direction: column;
`;
