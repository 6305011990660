import styled from 'styled-components';
import { FilterChipStyled } from '../FilterChipStyled';
import media from '../../../styles/media';
import { rem } from 'polished';

export const FilterChipStyledAP = styled(FilterChipStyled)`
    font-size: ${({ theme }) => theme.fontSizes.textBase};
    color: ${({ theme }) => theme.colors.black};
    font-weight: ${({ theme }) => theme.fontWeights.textRegular};
    border: 1px solid ${({ theme }) => theme.colors.black};

    &:hover,
    &:focus-visible {
        color: ${({ theme }) => theme.colors.white};
        background: ${({ theme }) => theme.colors.black};
    }

    &.selected {
        background: #ccedf9;
        border-color: ${({ theme }) => theme.colors.primary};

        &:hover,
        &:focus-visible {
            color: ${({ theme }) => theme.colors.black};
        }
    }

    .count {
        background: ${({ theme }) => theme.colors.primary};
    }
`;
