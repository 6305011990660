import React, { FC, useState } from 'react';
import useTranslations from '@hooks/useTranslations';

import Icon, { Icons } from '../Icon';

import { MultiSelectProps } from './index';
import { IFilterRule } from '../../redux/filters/filter.duck.interface';
import { AccessibleButton } from '@components/Button/AccessibleButton';
import Checkbox from '@components/Checkbox';

export const MultiSelectTemplate: FC<MultiSelectProps> = ({ className, options, handleChange: handleParentChange }) => {
    const { t } = useTranslations();

    const [isOpen, setIsOpen] = useState(false);

    const handleChange = (filter: any) => {
        handleParentChange(filter);
    };

    return (
        <div className={className}>
            {options?.map((filter: IFilterRule) => (
                <label key={filter.code} className="container">
                    <Checkbox
                        name={`${filter.label}-${filter.code}`}
                        disabled={!isOpen}
                        label={filter.label}
                        checked={!!filter.value}
                        onChange={() => handleChange(filter)}
                    />
                </label>
            ))}
        </div>
    );
};
