import {BRAND, isBrandOV, isMarketGB} from '../../constants/main';
import { FilterChipStyledAC } from './AC/FilterChipStyled.AC';
import { FilterChipStyledOV } from './OV/FilterChipStyled.OV';
import { FilterChipStyledOV as FilterChipStyledOVGB } from './OV/GB/FilterChipStyled.OV';
import { FilterChipStyledAP } from './AP/FilterChipStyled.AP';
import { FilterChipStyledDS } from './DS/FilterChipStyled.DS';
import { FilterChipStyled } from './FilterChipStyled';
import { SC } from '../../styles/theme';

export type FilterChipProps = SC & {
    index?: number;
    onClick?: any;
    label?: any;
    minPrice?: number;
    maxPrice?: number;
    customClassName?: string;
    countOfSelectedFilters?: number;
    dataId?: string;
};

const FilterChip = (props: FilterChipProps) => {
    if (BRAND === 'AC') return <FilterChipStyledAC {...props} />;
    if (BRAND === 'OV') return isMarketGB ? <FilterChipStyledOVGB {...props} /> : <FilterChipStyledOV {...props} />;
    if (BRAND === 'AP') return <FilterChipStyledAP {...props} />;
    if (BRAND === 'DS') return <FilterChipStyledDS {...props} />;
    return <FilterChipStyled {...props} />;
};

export default FilterChip;
