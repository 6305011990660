import styled from 'styled-components';
import { breakpoints } from '../../../styles/theme';
import { FilterChipStyled } from '../FilterChipStyled';
import media from '../../../styles/media';
import { rem } from 'polished';

export const FilterChipStyledAC = styled(FilterChipStyled)`
    height: ${rem(37)};

    .count {
        width: ${rem(16)};
        height: ${rem(16)};
        padding-top: ${rem(2)};
    }
`;
