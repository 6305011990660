import React, { FC, useEffect, useState, useCallback, useMemo, memo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FilterBoxProps } from './index';
import { useTranslations } from '@hooks/useTranslations';
import { HandledComponentError } from '@components/ErrorHandling';
import AccessibleButton from '@components/Button/AccessibleButton';
import UIDuck from '../../redux/commonDucks/ui.duck';
import GlobalDuck from '../../redux/global/global.duck';
import { ENERGIES, FUEL, GEARBOX, GEARBOX_TYPES, PRICES } from '../../services';
import PriceSelectRange from './sections/PriceSelectRange';
import Checkbox from '@components/Checkbox';
import { buildParameterizedPathname } from '@utils/url.utils';
import { removeLanguageFromPathname } from '@utils/misc.utils';
import { useCustomRouter } from '@hooks/useCustomRouter';
import { FILTER_CATEGORY, isBrandAC, isBrandOV, isMarketGB, ParametrizedPathRegExp } from '../../constants/main';
import { removeLastCharacterFromPathname } from '@utils/appConfig.utils';
import { IFilterRule, IPriceRange, ISelectFilter } from '../../redux/filters/filter.duck.interface';
import { filtersToUrl, modifyFilters } from '../../services/filters/utils/Filters.utils';
import { useGTM } from '@hooks/useGTM';
import { stringify } from 'query-string';
import { theme } from '../../styles/theme';
import Spinner from '@components/Spinner';
import { useUIDuck } from '@hooks/useUIDuck';
import { useCarStockJourneyCheck } from '@hooks/useCarStockJourneyCheck';
import LocationInput from '@components/FilterBox/Components/LocationInput';
import { ICategoryWithFilters } from '@components/FilterBox/Components/GeneratedExtraFilters';
import ColorFilterItem from '@components/FilterBox/Components/ColorFilterItem';
import MultiSelect from '@components/MultiSelect';
import FilterDuck from 'src/redux/filters/filter.duck';
import { Redux } from 'src/redux/redux.interface';
import Routes from '../../constants/routes';
import TrimSelectorDuck from '../../redux/trimSelector/trimSelector.duck';
import OfferListDuck from '../../redux/carList/offerlist.duck';
import { useRouter } from 'next/router';
import DealerDuck from '../../redux/dealer/dealer.duck';
import { useGlobalDuck } from '@hooks/useGlobalDuck';
import Button from '@components/Button';

export enum GTMActionValues {
    APPLY = 'Apply',
    REMOVE = 'Remove',
    FILTER = 'Filter',
    PRICE_VIEW = 'PriceView',
    PRICE = 'Price',
    MAX = 'max',
    MIN = 'min',
}

const RegisteredFiltersForUnifiedGTMUpdate = [
    FILTER_CATEGORY.ENERGIES,
    FILTER_CATEGORY.BODY_STYLES,
    FILTER_CATEGORY.COLOR_GROUPS,
    FILTER_CATEGORY.GEARBOX_TYPES,
    FILTER_CATEGORY.TRANSMISSION_TYPES,
];

const MODAL_HEADER_HEIGHT = 80;
const MODAL_WRAPPER_ID = 'modal_filters_wrapper';
const MODAL_BODY_ID = 'modal_filters_body';

export const capitalizeFirstLetter = (value: string) => value.charAt(0).toUpperCase() + value.slice(1);

const FilterBoxTemplate: FC<FilterBoxProps> = memo(
    ({ className, isOpen, closeFilter, onChange, onReset, onClickCheckbox, onClickCTA, selectedCategoryName }) => {
        try {
            const { t } = useTranslations();
            const dispatch = useDispatch();
            const router = useRouter();
            const { pushGTMAction, pushUserAction } = useGTM();
            const { isStockJourney } = useCarStockJourneyCheck();

            const { isLoading } = useUIDuck();

            const filters = useSelector((state: Redux) => FilterDuck.getFilters(state));
            const availableFilters = useSelector((state: Redux) => FilterDuck.getFilteredFilters(state));
            const sort = useSelector((state: Redux) => FilterDuck.getSorting(state));
            const filterCategories = useSelector((state: Redux) => FilterDuck.getFilterCategories(state));
            const priceRange = useSelector((state: Redux) => FilterDuck.getPriceRange(state));
            const selectedDealer = useSelector((state: Redux) => DealerDuck.getOwnState(state).dealer);
            const selectedDealerId = useSelector((state: Redux) => FilterDuck.getOwnState(state).dealerId);
            const isRouting = useSelector((state: Redux) => UIDuck.getIsRouting(state));
            const { isNoCarError } = useUIDuck();
            const { carJourney } = useGlobalDuck();
            const geoLocation = useSelector((state: Redux) => FilterDuck.getGeoLocation(state));
            const distanceRadius = useSelector((state: Redux) => FilterDuck.getDistanceRadius(state));
            const getGeoLocationName = useSelector((state: Redux) => FilterDuck.getGeoLocationName(state));
            const stockTrimPageVehiclesCount = useSelector(
                (state: Redux) => TrimSelectorDuck.getOwnState(state).stock.totalFilteredItems
            );
            const stockHomePageVehiclesCount = useSelector((state: Redux) =>
                OfferListDuck.getStockMatchingVehiclesCount(state)
            );

            const [oldPrices, setOldPrices] = useState(priceRange);

            const q = typeof window !== 'undefined' ? location.search : stringify(router.query);
            const queryString = filtersToUrl(
                filterCategories,
                filters,
                sort,
                q,
                geoLocation,
                distanceRadius,
                getGeoLocationName,
                priceRange,
                !router.pathname.includes(Routes.SELECTOR),
                selectedDealer ? selectedDealerId : null
            );

            const formattedPathname = buildParameterizedPathname(
                router.asPath.replace(ParametrizedPathRegExp, ''),
                carJourney
            );

            useEffect(() => {
                const modalWrapper = document.getElementById(MODAL_WRAPPER_ID);
                const modalBody = document.getElementById(MODAL_BODY_ID);

                if (modalBody.offsetHeight < modalWrapper.clientHeight) {
                    modalWrapper.style.overflowY = 'hidden';
                }

                if (selectedCategoryName) {
                    const element = document.getElementById(selectedCategoryName);

                    modalWrapper.scrollTo({ top: element.offsetTop - MODAL_HEADER_HEIGHT, behavior: 'smooth' });
                }
            }, [selectedCategoryName]);

            const filterButtons = useMemo(
                () =>
                    filters
                        .filter((f) => f.displayName)
                        .map((filter) => ({
                            ...filter,
                            disabled:
                                availableFilters.find((filteredFilter) => filter.name === filteredFilter.name) ===
                                undefined,
                        })),
                [filters, availableFilters]
            );

            const extraFilterButtons = useMemo(
                () =>
                    filters.filter(({ parent }) =>
                        [FILTER_CATEGORY.COLOR_GROUPS, FILTER_CATEGORY.ENGINES, FILTER_CATEGORY.GRADES].includes(
                            parent as FILTER_CATEGORY
                        )
                    ),
                [filters]
            );

            const priceData = useMemo(() => filterButtons.find((field) => field.parent === PRICES), [filterButtons]);
            const fuelData = useMemo(
                () => filterButtons.filter((field) => field.parent === (isStockJourney ? ENERGIES : FUEL)),
                [filterButtons]
            );
            const gearboxData = useMemo(
                () => filterButtons.filter((field) => field.parent === (isStockJourney ? GEARBOX_TYPES : GEARBOX)),
                [filterButtons]
            );

            useEffect(() => {
                if (filters) {
                    const doQueriesMatch = window.location.search === queryString;

                    const path = router.asPath.replace(ParametrizedPathRegExp, '');

                    const doPathNamesMatch =
                        removeLastCharacterFromPathname(path, '/') ===
                        removeLastCharacterFromPathname(formattedPathname, '/');

                    // Fist dealer is automatically preselected when address is adding to query
                    const addedDealerIdWhenIsRouting =
                        !window.location?.search?.includes('idsitegeo') && queryString?.includes('idsitegeo');

                    if (
                        (!doQueriesMatch || !doPathNamesMatch) &&
                        (!isRouting || addedDealerIdWhenIsRouting) &&
                        carJourney
                    ) {
                        const query: Record<string, string> = {};
                        new URLSearchParams(queryString).forEach((value, key) => (query[key] = value));
                        router.replace({
                            pathname: formattedPathname,
                            query,
                        });
                    }
                }
            }, [queryString, formattedPathname]);

            const handleStockChange = useCallback(
                (name: string, value: boolean | IPriceRange) => {
                    const newFilters = modifyFilters(filters, name, value);

                    dispatch(FilterDuck.setFilters(newFilters, geoLocation, distanceRadius));

                    const gtmRegisteredFilter = RegisteredFiltersForUnifiedGTMUpdate.find((gtmFilter) =>
                        name.includes(gtmFilter)
                    );

                    pushGTMAction(name, value, oldPrices);

                    if (gtmRegisteredFilter) {
                        const gtmAction = value ? GTMActionValues.APPLY : GTMActionValues.REMOVE;

                        pushUserAction({
                            action: `${GTMActionValues.FILTER}::${capitalizeFirstLetter(gtmRegisteredFilter)}`,
                            label: `${gtmAction}::${name.replace(`${gtmRegisteredFilter}.`, '')}`,
                        });
                    }

                    if (name === FILTER_CATEGORY.COLOR) {
                        pushUserAction({
                            action: `${GTMActionValues.FILTER}::${capitalizeFirstLetter(FILTER_CATEGORY.COLOR)}`,
                            label: String(value),
                        });
                    }

                    if (name === FILTER_CATEGORY.PRICES) {
                        const { min: oldMin, max: oldMax } = oldPrices;
                        const { min: newMin, max: newMax } = value as IPriceRange;

                        if (oldMin !== newMin) {
                            pushUserAction({
                                action: `${GTMActionValues.FILTER}::${GTMActionValues.PRICE}`,
                                label: `${GTMActionValues.MIN}`,
                                value: String(newMin),
                            });
                        }

                        if (oldMax !== newMax) {
                            pushUserAction({
                                action: `${GTMActionValues.FILTER}::${GTMActionValues.PRICE}`,
                                label: `${GTMActionValues.MAX}`,
                                value: String(newMax),
                            });
                        }

                        setOldPrices(value as IPriceRange);
                    }
                },
                [filters, oldPrices, onChange, dispatch, pushGTMAction]
            );

            const handleChange = useCallback(
                (name: string, value: boolean | IPriceRange) => {
                    const newFilters = modifyFilters(filters, name, value);

                    dispatch(FilterDuck.setFilters(newFilters, geoLocation, distanceRadius));

                    onChange?.(name, newFilters);

                    pushGTMAction(name, value, oldPrices);

                    if (name === PRICES) {
                        setOldPrices(value as IPriceRange);
                    }
                },
                [filters, oldPrices, onChange, dispatch, pushGTMAction]
            );

            const handleStockSelectChange = useCallback(
                (value: ISelectFilter) => {
                    pushUserAction({
                        action:
                            (value as { parent: string }).parent === FILTER_CATEGORY.GRADES
                                ? `${GTMActionValues.FILTER}::${capitalizeFirstLetter(FILTER_CATEGORY.FINITION)}`
                                : `${GTMActionValues.FILTER}::${capitalizeFirstLetter(FILTER_CATEGORY.MOTORIZATION)}`,
                        label: value.label,
                    });

                    handleChange(value.name, !value.value);
                },
                [handleChange]
            );

            const resetFilters = () => {
                onReset?.();

                dispatch(FilterDuck.resetFilters(filters));
            };

            const onFilterApply = () => {
                closeFilter();
                if (typeof onClickCTA === 'function') {
                    onClickCTA(t(`filters.submit`));
                }
            };

            const mappedExtraFilterByCategories: ICategoryWithFilters = extraFilterButtons.reduce((acc, filter) => {
                const isEnabled = availableFilters.find((availableFilter) => availableFilter.name === filter.name);

                if (!acc[filter.parent]?.length) {
                    acc[filter.parent] = [];
                }

                if (filter.parent !== (FILTER_CATEGORY.COLOR_GROUPS as string)) {
                    const mappedFilter: ISelectFilter = {
                        label: filter.label,
                        value: filter.value as boolean,
                        disabled: !isEnabled,
                        name: filter.name,
                        parent: filter.parent,
                    };

                    return {
                        ...acc,
                        [filter.parent]: [...acc[filter.parent], mappedFilter],
                    };
                }

                return {
                    ...acc,
                    [filter.parent]: [...acc[filter.parent], { ...filter, disabled: !isEnabled }],
                };
            }, {} as ICategoryWithFilters);

            useEffect(() => {
                if (isOpen) {
                    document.body.style.overflow = 'hidden';
                } else {
                    document.body.style.removeProperty('overflow');
                }
            }, [isOpen]);

            const isTrimPage = router.pathname.includes(Routes.SELECTOR);

            return (
                <div className={`${className} ${isOpen ? 'isOpen' : 'isClose'}`} id={MODAL_WRAPPER_ID}>
                    {isLoading && <Spinner size={25} border={3} color={theme.colors.black} />}
                    <div className={isLoading ? 'loading' : ''} id={MODAL_BODY_ID}>
                        <div className="header">
                            <span className="title">{t('filters.filters')}</span>
                            <AccessibleButton
                                className="closeButton"
                                onClick={closeFilter}
                                data-testid="TESTING_CLOSE_MODAL"
                            >
                                &#10005;
                            </AccessibleButton>
                        </div>
                        <div id={FILTER_CATEGORY.FUEL}>
                            <div className="energy">
                                <span>{t('filters.category.fuel')}</span>
                                {fuelData.map((dataField, key) => (
                                    <Checkbox
                                        key={`${dataField.displayName}-${key}`}
                                        className={`energyItem energyItem_${key} ${
                                            dataField.value ? 'checked' : dataField.disabled ? 'disabled' : ''
                                        }`}
                                        onChange={() => {
                                            handleChange(dataField.name, !dataField.value);
                                            onClickCheckbox?.({ type: dataField.displayName, value: !dataField.value });
                                        }}
                                        checked={dataField.value as boolean}
                                        name={t(dataField.displayName)}
                                        disabled={dataField.disabled}
                                        label={t(dataField.displayName)}
                                        dataTestId={`TESTING_FILTER_CATEGORY_FUEL_ITEM_${dataField?.displayName?.toUpperCase()}`}
                                    />
                                ))}
                            </div>
                        </div>
                        {(!isBrandOV || !isMarketGB || isStockJourney) && (
                            <div id={FILTER_CATEGORY.GEARBOX}>
                                <div className="gear">
                                    <span>{t('filters.category.gearbox')}</span>
                                    {gearboxData.map((dataField, key) => (
                                        <Checkbox
                                            key={`${dataField.displayName}-${key}`}
                                            className={`gearItem gearItem_${key} ${
                                                dataField.value ? 'checked' : dataField.disabled ? 'disabled' : ''
                                            }`}
                                            onChange={() => {
                                                handleChange(dataField.name, !dataField.value);
                                                onClickCheckbox?.({
                                                    type: dataField.displayName,
                                                    value: !dataField.value,
                                                });
                                            }}
                                            checked={dataField.value as boolean}
                                            name={t(dataField.displayName)}
                                            disabled={dataField.disabled}
                                            label={t(dataField.displayName)}
                                            dataTestId={`TESTING_FILTER_CATEGORY_GEARBOX_ITEM_${dataField?.displayName?.toUpperCase()}`}
                                        />
                                    ))}
                                </div>
                            </div>
                        )}
                        <div className="priceRange" id={FILTER_CATEGORY.PRICES}>
                            <span>{t('filters.category.paymentJourneyType')}</span>
                            <PriceSelectRange data={priceData} handleChange={handleChange} priceRange={priceRange} />
                        </div>
                        {isStockJourney && isTrimPage && (
                            <div className="engines" id={FILTER_CATEGORY.ENGINES}>
                                <span>{t('filters.category.engines')}</span>
                                <MultiSelect
                                    options={(
                                        mappedExtraFilterByCategories[Object.keys(mappedExtraFilterByCategories)[2]] ||
                                        mappedExtraFilterByCategories?.engines
                                    )?.filter((f) => !f.disabled)}
                                    handleChange={handleStockSelectChange}
                                />
                            </div>
                        )}
                        {isStockJourney && isTrimPage && (
                            <div className="grades" id={FILTER_CATEGORY.GRADES}>
                                <span>{t('filters.category.grades')}</span>
                                <MultiSelect
                                    options={(
                                        mappedExtraFilterByCategories[Object.keys(mappedExtraFilterByCategories)[0]] ||
                                        mappedExtraFilterByCategories?.grades
                                    )?.filter((f) => !f.disabled)}
                                    handleChange={handleStockSelectChange}
                                />
                            </div>
                        )}
                        {isStockJourney && isTrimPage && (
                            <div className="colors" id={FILTER_CATEGORY.COLOR}>
                                <span>{t('filters.category.colorGroups')}</span>
                                {mappedExtraFilterByCategories[FILTER_CATEGORY.COLOR_GROUPS]?.map(
                                    (filter: IFilterRule) => (
                                        <ColorFilterItem
                                            key={filter.name}
                                            isActive={filter.value as boolean}
                                            color={filter.hexCode}
                                            ariaLabel={`${t('colorSwitcher.ariaLabel')} ${filter.displayName}`}
                                            className="color-filter-item"
                                            handleClick={() => handleStockChange(filter.name, !filter.value)}
                                        />
                                    )
                                )}
                            </div>
                        )}

                        {isNoCarError && (
                            <div className="error">
                                <span>{t('filters.category.noCarError')}</span>
                            </div>
                        )}
                        <div className="button">
                            <Button
                                onClick={resetFilters}
                                secondary={!isBrandAC}
                                primary={isBrandAC}
                                withoutBackground={isBrandAC}
                                data-testid="TESTING_FILTER_RESET_BUTTON"
                            >
                                {t(`filters.reset`)}
                            </Button>
                            <Button onClick={onFilterApply} primary data-testid="TESTING_FILTER_VALIDATE_BUTTON">
                                {t(`filters.submit`)}{' '}
                                {!router.pathname.includes(Routes.SELECTOR) &&
                                stockHomePageVehiclesCount > 0 &&
                                isStockJourney
                                    ? `(${stockHomePageVehiclesCount})`
                                    : ''}
                                {router.pathname.includes(Routes.SELECTOR) &&
                                stockTrimPageVehiclesCount > 0 &&
                                isStockJourney
                                    ? `(${stockTrimPageVehiclesCount})`
                                    : ''}
                            </Button>
                        </div>
                    </div>
                </div>
            );
        } catch (e: any) {
            return <HandledComponentError error={e} />;
        }
    }
);
FilterBoxTemplate.displayName = 'FilterBoxTemplate';
export default FilterBoxTemplate;
