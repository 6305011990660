import React, { FC } from 'react';
import Range from '../../Range';
import { IPriceRange, IFilterRule } from '../../../redux/filters/filter.duck.interface';
import { RANGE_STEP } from 'src/constants/main';

interface PriceSelectRangeProps {
    priceRange: IPriceRange;
    data: IFilterRule;
    handleChange: (name: string, value: boolean | IPriceRange) => void;
}

const ALLOWED_STEPS = [100, 90, 80, 70, 60, 50, 40, 30, 20, 10];

// TODO: create handle priceRange change

const PriceSelectRange: FC<PriceSelectRangeProps> = React.memo(({ data, priceRange, handleChange }) => {
    const findMaxAllowedStep = () => {
        const allowedSteps = ALLOWED_STEPS.map((allowedStep) => {
            if (((priceRange.availableMax - priceRange.availableMin) / allowedStep) % 1 === 0) {
                return allowedStep;
            }
        });
        return allowedSteps.filter((allowedStep) => allowedStep)?.[0] || RANGE_STEP;
    };

    return (
        <div className="filter-grid__prices" data-testid="TESTING_FILTER_CATEGORY_PRICE_RANGE">
            <Range
                className="slider-over"
                min={priceRange.min}
                max={priceRange.max}
                availableMin={priceRange.availableMin}
                availableMax={priceRange.availableMax}
                step={findMaxAllowedStep()}
                value={
                    data?.value
                        ? [(data.value as IPriceRange).min, (data.value as IPriceRange).max]
                        : [priceRange.min, priceRange.max]
                }
                onAfterChange={(val: Array<number>) => handleChange(data.name, { min: val[0], max: val[1] })}
            />
        </div>
    );
});

PriceSelectRange.displayName = 'PriceSelectRange';
export default PriceSelectRange;
