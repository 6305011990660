import styled from 'styled-components';
import { FilterBoxStyled } from '../FilterBoxStyled';
import { rem } from 'polished';

export const FilterBoxStyledOV = styled(FilterBoxStyled)`
    background: ${({ theme }) => theme.colors.pureWhite};

    .header {
        background: ${({ theme }) => theme.colors.pureWhite};

        .title {
            font-size: ${({ theme }) => theme.fontSizes.h2};
        }

        .closeButton {
            font-size: ${({ theme }) => theme.fontSizes.h3};
        }
    }

    .button {
        background: ${({ theme }) => theme.colors.pureWhite};
    }

    .energy,
    .gear,
    .priceRange {
        font-size: ${({ theme }) => theme.fontSizes.h5};
        padding-top: 0;

        > span {
            font-size: ${rem(20)};
            padding-bottom: ${rem(15)};
        }
    }
`;
