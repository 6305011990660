import styled from 'styled-components';
import { breakpoints } from '../../styles/theme';
import { FilterChipTemplate } from './FilterChipTemplate';
import { rem } from 'polished';

export const FilterChipStyled = styled(FilterChipTemplate)`
    font-size: ${({ theme }) => theme.fontSizes.h6};
    color: ${({ theme }) => theme.colors.grey5};
    font-weight: ${({ theme }) => theme.fontWeights.textBold};
    padding: ${rem(9)} ${rem(16)};
    background: unset;
    border-radius: ${rem(26)};
    border: 2px solid ${({ theme }) => theme.colors.grey5};
    cursor: pointer;
    white-space: nowrap;
    margin: ${rem(5)} ${rem(8)} ${rem(5)} 0;
    width: min-content;
    height: ${rem(37)};

    &:hover,
    &:focus-visible {
        color: ${({ theme }) => theme.colors.blue2};
        border-color: ${({ theme }) => theme.colors.blue2};
    }

    &.selected {
        background: #eef0f3;
        color: ${({ theme }) => theme.colors.blue2};
        border-color: ${({ theme }) => theme.colors.blue2};
    }

    .count {
        margin-left: ${rem(5)};
        background: ${({ theme }) => theme.colors.blue2};
        width: ${rem(20)};
        height: ${rem(19)};
        text-align: center;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        color: ${({ theme }) => theme.colors.white};
    }
`;
