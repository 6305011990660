import React, { FC, useState, useEffect, useCallback } from 'react';
import { Range } from 'rc-slider';
import { RangeSliderProps } from '.';
import { useMoneyFormatter } from '@hooks/useMoneyFormatter';

const MINIMUM_DISTANCE = 1000;

export const RangeTemplate: FC<RangeSliderProps> = ({
    className,
    min,
    max,
    availableMin,
    availableMax,
    defaultValue,
    value,
    onChange,
    ...rest
}) => {
    const { formatMoney } = useMoneyFormatter();

    const [curVal, setCurVal] = useState(value || defaultValue || [0, 1]);
    const setValues = useCallback(
        (value) => {
            value[0] = value[0] < availableMin ? availableMin : value[0];
            value[1] = value[1] > availableMax ? availableMax : value[1];
            if (availableMin && availableMax) {
                const [min, max] = [Math.max(value[0], availableMin), Math.min(value[1], availableMax)];
                if (curVal[0] != value[0]) {
                    setCurVal([min === max ? min - 5 : min, max]);
                }

                if (curVal[1] != value[1]) {
                    setCurVal([min, max === min ? max + 5 : max]);
                }
            } else {
                setCurVal(value);
            }
        },
        [availableMin, availableMax, value]
    );

    useEffect(() => {
        setValues(value);
    }, [value, setValues]);

    return (
        <div className={className}>
            <Range
                onChange={setValues}
                onAfterChange={onChange}
                value={curVal}
                min={min}
                max={max}
                allowCross={false}
                step={1}
                {...rest}
                pushable={MINIMUM_DISTANCE as any}
            />
            <div className="range-display">
                <span data-testid="TESTING_START_RANGE">{formatMoney(curVal[0], true)}</span>
                <span data-testid="TESTING_END_RANGE">{formatMoney(curVal[1], true)}</span>
            </div>
        </div>
    );
};
