import styled from 'styled-components';
import { breakpoints } from '../../../styles/theme';
import { FilterChipStyled } from '../FilterChipStyled';
import media from '../../../styles/media';
import { rem } from 'polished';

export const FilterChipStyledOV = styled(FilterChipStyled)`
    color: ${({ theme }) => theme.colors.black2};
    font-weight: ${({ theme }) => theme.fontWeights.textRegular};
    font-size: ${({ theme }) => theme.fontSizes.h5};

    &:hover,
    &:focus-visible,
    &.selected {
        background: rgba(0, 0, 0, 0.1);
        background: ${({ theme }) => theme.colors.primary};
    }

    .count {
        width: ${rem(18)};
        height: ${rem(18)};
        background: ${({ theme }) => theme.colors.black2};
    }
`;
