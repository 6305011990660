import styled from 'styled-components';
import { FilterBoxStyled } from '../FilterBoxStyled';
import { rem } from 'polished';

export const FilterBoxStyledAC = styled(FilterBoxStyled)`
    padding: ${rem(14)} ${rem(32)};

    .header {
        .title {
            font-size: ${rem(30)};
            color: ${({ theme }) => theme.colors.grey5};
            padding-left: 0;
        }

        .closeButton {
            font-size: ${({ theme }) => theme.fontSizes.h3};
            padding-right: 0;
        }
    }

    .energy,
    .gear,
    .location,
    .colors,
    .engines,
    .grades,
    .priceRange {
        font-size: ${({ theme }) => theme.fontSizes.h4};
        color: ${({ theme }) => theme.colors.grey5};
        margin: ${rem(19)} 0;

        > span {
            font-size: ${({ theme }) => theme.fontSizes.h3};
            padding-bottom: ${rem(17)};
        }

        input + label::before {
            border-radius: ${rem(4)};
        }

        .checked label {
            color: ${({ theme }) => theme.colors.blue2};
        }
    }

    .priceRange {
        padding: 0 0 ${rem(19)} 0;
    }
`;
