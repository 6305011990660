import styled from 'styled-components';
import { breakpoints } from '../../../styles/theme';
import { RangeStyled } from '../RangeStyled';
import { rem } from 'polished';

export const RangeStyledDS = styled(RangeStyled)`
    margin-top: 0;

    .rc-slider {
        position: relative;
        height: 1.5rem;
        width: 90%;
        padding: 5px 0;
    }
    .rc-slider-rail {
        background-color: ${(props) => props.theme.colors.grey4};
        height: ${rem('3px')};
        position: absolute;
        width: 100%;
        border-radius: 0.25rem;
    }
    .rc-slider-track {
        background-color: ${(props) => props.theme.colors.primary};
        height: ${rem('3px')};
        position: absolute;
        left: 0;
        border-radius: 0.25rem;
    }
    .rc-slider-handle {
        background-color: ${(props) => props.theme.colors.primary};
        border: solid 1px ${(props) => props.theme.colors.primary};
        width: ${rem(15)};
        height: ${rem(15)};
        cursor: grab;
        position: absolute;
        margin-top: ${rem(-6)};
        border-radius: 50%;
    }

    .rc-slider-handle-dragging {
        box-shadow: 0 0 2px 2px ${(props) => props.theme.colors.primary};
        background: ${(props) => props.theme.colors.primary};
    }
    .rc-slider-handle {
        &:focus-visible {
            box-shadow: none;
            outline: 3px solid ${({ theme }) => theme.colors.grey4} !important;
            background: ${({ theme }) => theme.colors.primary};
        }
        :hover {
            box-shadow: 0 0 1px 1px ${(props) => props.theme.colors.primary};
        }
    }
    .range-display {
        color: ${(props) => props.theme.colors.black1};
        font-weight: ${({ theme }) => theme.fontWeights.textBold};
    }
`;
