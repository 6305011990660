import styled from 'styled-components';
import { breakpoints } from '../../../styles/theme';
import { FilterChipStyled } from '../FilterChipStyled';
import media from '../../../styles/media';
import { rem } from 'polished';

export const FilterChipStyledDS = styled(FilterChipStyled)`
    color: ${({ theme }) => theme.colors.black};
    border: 1px solid ${({ theme }) => theme.colors.black};
    font-weight: ${({ theme }) => theme.fontWeights.textRegular};
    font-size: ${({ theme }) => theme.fontSizes.h5};

    &:hover,
    &:focus-visible,
    &.selected {
        background: ${({ theme }) => theme.colors.grey4};
        border-color: ${({ theme }) => theme.colors.primary};
        color: ${({ theme }) => theme.colors.black};
    }

    .count {
        background: ${({ theme }) => theme.colors.primary};
    }
`;
